
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      boothNumber: null,
    };
  },
  computed: {
    ...mapGetters([
      'currentCompanyPackage',
      'activeEditionDetails',
      'companyServices',
      'activeEdition',
      'namedDetails',
      'companyGuardian'
    ]),
  },
  mounted() {
    this.boothNumber = this.namedDetails[`${this.activeEdition}_ed_booth_number`];
  },
  watch: {
    activeEdition: function() {
      this.$store.dispatch('getCompanyGuardian');
      this.boothNumber = this.namedDetails[`${this.activeEdition}_ed_booth_number`];
    },
  },
});
